/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';

const Wix = ({ sliderSettings }) => {
  const { img1 } = useStaticQuery(graphql`
    query {
      img1: file(relativePath: { eq: "help/wix/wix_4.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const photos = [
    {
      img: img1.childImageSharp.fluid,
      alt: 'Wix Integration',
      title: 'Integrate Wix with LiveSession',
    },
  ];

  return (
    <>
      <Slider {...sliderSettings(photos)} className="without-dots">
        <div>
          <Img fluid={photos[0].img} alt={photos[0].alt} title={photos[0].title} />
        </div>
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Wix is one of the most popular and user-friendly website builders out there. When you
          create your website with Wix, you can see how people interact with it, thanks to
          LiveSession. A simple tracking code will help you get interesting insights on user
          behavior.
        </p>
        <p>Please note that you need a premium Wix account to install a LiveSession code.</p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Open recordings straight from the Analytics dashboard.</li>
          <li>Access qualitative and quantitative data from one place.</li>
          <li>Get more insights about your users.</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        Just copy and paste a piece of code, as described in our{' '}
        <a href="/help/how-install-on-wix/">help center</a>.
      </p>
    </>
  );
};

export default withIntegrationCardContext(Wix);

Wix.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};
